import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";

const UpdatePage = () => {
  const [count, setCount] = useState(2 * 86400);

  useEffect(() => {
    const endDate = new Date("2024-01-18T19:00:00");
    const interval = setInterval(() => {
      const now = new Date();
      setCount(Math.floor((endDate - now) / 1000));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  if (count < 0) {
    return null;
  }

  const days = Math.floor(count / 86400);
  const hours = Math.floor((count % 86400) / 3600);
  const minutes = Math.floor((count % 3600) / 60);
  const seconds = count % 60;

  return (
    <>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-iYQeCzEYFbKjA/T2uDLTpkwGzCiq6soy8tYaI1GyVh/UjpbCx/TYkiZhlZB6+fzT"
        crossorigin="anonymous"
      ></link>
      <Navbar />
      <div className="d-flex align-items-center h-100">
        <div className=" text-center">
          <br />
          <br />
          <br />
          <h1 className="text-primary">Atualização em andamento</h1>
          <p>
            Estamos trabalhando para tornar sua experiência conosco ainda
            melhor. Adicionaremos novas funcionalidades para melhor atender às
            suas necessidades.
          </p>
          <p>
            <strong className="text-danger">
              Data: 15/02 a 17/02 - Hora: 19 horas
            </strong>
          </p>
          <p>
            <strong className="text-danger countdown">
              Tempo restante: {days} dias {hours}:
              {minutes.toString().padStart(2, "0")}:
              {seconds.toString().padStart(2, "0")}
            </strong>
          </p>
          <p>
            Agradecemos sua paciência enquanto trabalhamos para melhorar nosso
            serviço.
          </p>
        </div>
        <style>
          {`
            .countdown {
              animation: countdown 1s ease-in-out infinite;
            }

            @keyframes countdown {
              0% {
                opacity: 1;
              }
              50% {
                opacity: 0.5;
              }
              100% {
                opacity: 1;
              }
            }
          `}
        </style>
      </div>
    </>
  );
};

export default UpdatePage;
