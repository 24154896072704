import { Routes, Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';

import Form from './components/Form';
import About from './pages/aboutus/aboutus';
import Informations from './components/InformationsHome';
import Tips from './pages/tips/tips';
import Recomendations from './pages/recomendations/recomendations';
import UpdatePage from './utils/UpdateSite';

export default function RoutesApp() {
  return (
    
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" exact element={<Form />} />
        <Route path="/test" exact element={<UpdatePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/informations" element={<Informations />} />
        <Route path="/tips" element={<Tips />} />
        <Route path="/recommendations" element={<Recomendations />} />
      </Routes>
    </BrowserRouter>
  );
}
