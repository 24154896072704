/** @format */

import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";

import logo from "../img/icon.png";
import githubIcon from "../img/github.png";
import instagramIcon from "../img/instagram.png";
import linkedinIcon from "../img/linkedin.png";

export default function Footer() {
  return (
    <>
      <div className="divfooter">
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4960559188017689"
          crossorigin="anonymous"></script>

        <footer class="text-center textStyle">
          <div className="pt-4 py-0 text-sm placeholder:text-zinc-500 appearance-none">
            <Alert
              className="alertfooter"
              severity="success"
              sx={{ bgcolor: "#000000", color: "#696969" }}>
              OBS: No momento estamos sem algumas marcas, mas já estudando suas
              compatibilidades, uma delas é a MSI por exemplo.
              <br />
              Também estamos sem memoria de 3600/4000/5000MHZ mas iremos
              adicionando aos poucos futuramente. Tentei ao máximo deixar
              configurações de placa de vídeo que não de gargalo. Caso verifique
              algo que não faz sentido ser compatível basta clicar no link
              abaixo.
              <br />
              <p>
                <strong>
                  <i>Viu algo de errado ou deseja da uma sugestão? </i>
                </strong>
                <a
                  href="https://discord.gg/Me9cn5pyEQ"
                  target="_blank"
                  rel="noreferrer">
                  Clique aqui
                </a>
              </p>
            </Alert>

            <br />

            <div className="textColorFooter">
              <h1 className="textColorFooter">É compatível?</h1>
              <h4 className="px-2">
                O site para você saber quais peças do PC são compativeis.
              </h4>
              <section class="px-4 py-1 mb-2">
                <ul className="px-4">
                  <div className="px-4 m-4">
                    <strong>
                      Eu não me responsabilizo por qualquer tipo de
                      incompatibilidade que venha a acontecer<br></br> caso
                      monte o seu PC pelas indicações do site: "é compatível?",
                      sendo de sua total responsabilidade.
                    </strong>
                  </div>
                </ul>

                <div className="sociaismidian d-flex justify-content-center align-items-center">
                  <a
                    class="btn btn-outline-light btn-floating mx-2 btnfooter"
                    href="https://github.com/JoaoVitorML-BR"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src={githubIcon}
                      alt="Icone do GitHub"
                      width={27}
                      height={24}
                    />{" "}
                    <br />
                    GitHub
                  </a>

                  <a
                    class="btn btn-outline-light btn-floating mx-2 btnfooter"
                    href="https://www.instagram.com/joao_vitor_0o/"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src={instagramIcon}
                      alt="Icone do instagram"
                      width={27}
                      height={24}
                    />{" "}
                    <br />
                    Instagram
                  </a>

                  <a
                    class="btn btn-outline-light btn-floating mx-2 btnfooter"
                    href="https://www.linkedin.com/in/jo%C3%A3o-vitorml-br/"
                    target="_blank"
                    rel="noreferrer">
                    <img
                      src={linkedinIcon}
                      alt="Icone do Linkedin"
                      width={27}
                      height={24}
                    />{" "}
                    <br />
                    Linkedin
                  </a>
                </div>

                <div className="sociaismidian d-flex justify-content-center align-items-center">
                  <a
                    class="btn btn-outline-light btn-floating mx-2 btnfooter"
                    href="/about">
                    <Link className="link-btn" to="/about">
                      <img
                        src={logo}
                        alt="Icone do GitHub"
                        width={27}
                        height={24}
                      />
                      <br />
                      Sobre nós
                    </Link>
                  </a>

                  <a
                    class="btn btn-outline-light btn-floating mx-2 btnfooter"
                    href="/tips">
                    <Link className="link-btn" to="/tips">
                      <img
                        src={logo}
                        alt="Icone do instagram"
                        width={27}
                        height={24}
                      />
                      <br />
                      Dicas{" "}
                    </Link>
                  </a>

                  <a
                    class="btn btn-outline-light btn-floating mx-2 btnfooter"
                    href="/informations">
                    <Link className="link-btn" to="/informations">
                      <img
                        src={logo}
                        alt="Icone do Facebook"
                        width={27}
                        height={24}
                      />{" "}
                      <br />
                      Como Usar
                    </Link>
                  </a>
                </div>

                <div class="textColorFooter text-center pt-0">
                  <p class="p-4">
                    © 2022 Copyright - Dev: <i>João Vitor</i>
                  </p>
                </div>
              </section>
            </div>
          </div>
        </footer>

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4960559188017689"
          crossorigin="anonymous"></script>
      </div>
    </>
  );
}
