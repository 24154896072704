/** @format */

export default function ScriptAnalytcs() {
  return (
    <>
      <header>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WHLKPVP9"
            height="0"
            width="0"
            style="display:none;visibility:hidden"></iframe>
        </noscript>
      </header>

      <body>
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WHLKPVP9"
            height="0"
            width="0"
            style="display:none;visibility:hidden"></iframe>
        </noscript>
      </body>
    </>
  );
}
