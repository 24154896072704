export const SelectStyle = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        borderColor: state.isFocused ? "grey" : "#00CED1",
        backgroundColor: "#00000",
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        backgroundColor: "#000000",
    }),
    option: (baseStyles, state) => ({
        ...baseStyles,
        backgroundColor: state.isSelected ? "#000000" : undefined,
        color: "#00CED1",
        borderRadius: "8px",
        '&:hover': {
            backgroundColor: "#1A1A1E",
        },
    }),
}