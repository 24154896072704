/** @format */

import Scripts from "../utils/Scripts";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Slider from "../pages/slider/slider";
import DonationsAnimated from "../utils/DonationsAnimated";
import Alert from "@mui/material/Alert";

import ScriptAnalytcs from "../utils/ScriptGoogleAnalytcs";

export default function Informations() {
  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustCenter">
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4960559188017689"
          crossorigin="anonymous"></script>
        <script
          type="text/javascript"
          src="//pl18279723.highcpmrevenuenetwork.com/f5/7d/b6/f57db6b22a8bdd38d827b79e2248fcec.js"></script>

        <Scripts />

        <Navbar />

        <br />

        <div className="divalerttop">
          <Alert
            className="alertprincipal"
            severity="info"
            sx={{ bgcolor: "#000000", color: "#696969" }}>
            <p>
              {" "}
              Não tem certeza de qual configuração escolher? Consulte nossa
              tabela de recomendações para encontrar a configuração ideal para
              você. Basta escolher o valor que você gostaria de investir e nós
              recomendaremos a configuração que melhor se adapta às suas
              necessidades.
              <a href="/recommendations" target="">
                <strong>
                  <i> Ver recomendações</i>
                </strong>
              </a>
            </p>
          </Alert>
          <br />
        </div>

        <div className="informationdiv container-expand-lg divform-informations">
          <h2>
            Informações sobre como funciona o uso do Verificador de
            compatibilidade.
          </h2>

          <h3>Primeiro passo</h3>
          <ul>
            <p>
              Primeiramente você irá definir a Marca que deseja usar, seja ela{" "}
              <strong>
                <i>"INTEL"</i>
              </strong>{" "}
              ou{" "}
              <strong>
                <i>"AMD" </i>{" "}
              </strong>{" "}
              Vale ressaltar que em caso de duvidas de qual marca você
              deseja/deve estar escolhendo, pode falar comigo através do discord
              ou redes sociais que está logo no final do site.
            </p>
          </ul>

          <h3>Segundo passo</h3>
          <ul>
            <p>
              Agora devemos escolher qual o processador que desejamos escolher,
              seja ele da linha R5/R7 no caso da AMD por exemplo ou mesmo os
              Core I5/I7 da INTEL por exemplo. Novamente reforço que em caso de
              dúvidas de como escolher uma boa configuração, pode falar comigo
              através das redes sociais ou acessar:{" "}
              <a href="/recommendations" target="">
                <strong>
                  <i>Recomendações de configuração</i>
                </strong>
              </a>
            </p>
          </ul>
          <h3>Terceiro passo</h3>
          <ul>
            <p>
              Agora chegou a hora de escolher a placa-mãe, vale ressaltar que as
              informações foram retiradas da lista das marcas de cada placa-mãe
              através de cada uma de suas desenvolvedoras, sejam elas: Gigabyte,
              ASUS, ASROCK, etc... Dito isto, você pode escolher a placa-mãe que
              será compatível com o processador. Pois de acordo com as suas
              fabricantes, eles tem compatibilidades com as marcas, mas vale
              ressaltar, é claro, que nem todos os processadores são logicamente
              recomendados usar nas placa-mãe, exemplo: Ryzen 9 3900 em uma
              A320M.
            </p>
          </ul>

          <h3>Quarto passo</h3>
          <ul>
            <p>
              Escolhido a placa-mãe, daremos continuidade escolhendo a
              memoria-ram, onde estará listada todas as memorias ram compativeis
              com a placa-mãe e CPU, por tanto, pode escolher sem medo de ser
              feliz, pois de acordo com as fabricantes a placa-mãe tem seu
              devido suporte a todas que aprecem listadas.
            </p>
          </ul>

          <h3>Quinto passo</h3>
          <ul>
            <p>
              Agora iremos escolher o nosso SSD, não se preocupe, pois a logica
              do site é retornar as penas os compativeis, logo, escolheremos
              algum para ilustrar a configuração é claro.
            </p>
          </ul>

          <h3>Sexto passo</h3>
          <ul>
            <p>
              Nessa etapa iremos escolher nossa placa de video, nessa etapa é
              importante se atentar a qual será escolhida, apesar de as
              fabricantes darem a garantia de ser compativél, pode ser que
              ocorra gargalos, tentei remover o máximo esse tipo de
              configuração, mas é sempre bom analisar corretamente se não dara
              gargalo realmente.
            </p>
          </ul>

          <h3>Setimo passo</h3>
          <ul>
            <p>
              Nesse nosso penultimo passo, iremos escolher a fonte, elas só são
              liberadas caso você selecione a placa de video, caso contrario,
              elas não irão ser mostradas. Levando em consideração que você as
              selecionou, basta você escolher alguma das retornadas (todas já
              previamente verificadas).
            </p>
          </ul>

          <h3>Ultimo passo</h3>
          <ul>
            <p>
              Feito todos os passos acima, finalmente podemos clicar no botão
              "verificar compatibilidade" onde o mesmo irá retornar "É
              compatível, e a fonte recomendada para aquela configuração", caso
              você tenha selecionado todas as opções, caso contrario irá te
              retornar uma mensagem pedindo para selecionar todas as opções.
            </p>
          </ul>

          {/* <video className='videoInformation' src="https://iscompatible-api.com.br/video-process" autoPlay controls></video> */}

          <Slider />

          <br />

          <DonationsAnimated />

          <Footer />
          <br />
          <br />
        </div>

        <br />
        <br />
      </div>
    </>
  );
}
