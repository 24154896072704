import axios from "axios";
import { useEffect, useState } from "react";
import { Heards } from "../../secure/auth/AuthTokenGetData";

import { UrlSite } from './UrlAccess';

export function UseCpusFetch(brandOption) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataCpu = async () => {
            try {
                if (brandOption) {
                    const res = await axios.get(`${UrlSite}/node-api/cpu/${brandOption}/DDR4`, Heards);
                    setData(res.data);
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchDataCpu();
    }, [brandOption]);

    return { data, loading, error };
}