/** @format */

import Carousel from "react-bootstrap/Carousel";

import memoryam from "../../img/adsense/aliexpressadsram.png";
import ssd from "../../img/adsense/aliexpressadssd.jpg";
import chair from "../../img/adsense/magaluadscadeira.jpg";
import notebook from "../../img/adsense/magaluadsnotebook.jpg";
import pcgamer from "../../img/adsense/magaluadspcgamer.jpg";
import monitor from "../../img/adsense/monitorgamer.png";

export default function MySlider() {
  return (
    <>
      <Carousel className="mycarousel">
        <Carousel.Item className="itemcarousel">
          <img
            className="d-block w-100 carouselimg"
            src={pcgamer}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 className="slidetext titleslider pt-2">
              {" "}
              PC GAMER CORE I5 - 8GB - GTX 1650 4G - 1TB
            </h5>
            <p className="slidetext">
              por <strong>R$ 2.499,00</strong> à vista ou{" "}
              <strong>R$ 2.499,00</strong> em <strong>10x de R$ 311,96</strong>{" "}
              sem juros.
            </p>
            <h6>
              <strong>CUPOM: 30EVOOFERTAS</strong>
            </h6>
            <a
              className="btn btn-outline-light btn-floating mx-2 btnfooter"
              href="https://www.magazinevoce.com.br/magazineEVOofertas/pc-gamer-smart-pc-smt81261-intel-i5-8gb-geforce-gtx-1650-4gb-1tb/p/4902993/IN/CPTG/?utm_source=EVOofertas&utm_medium=pc-gamer-smart-pc-smt81261-intel-i5-8gb-geforce-gt&utm_campaign=copy-paste&utm_content=copy-paste-share"
              target="_blank">
              Ver oferta
            </a>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="itemcarousel">
          <img
            className="d-block w-100 carouselimg"
            src={memoryam}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 className="slidetext titleslider">
              MEMÓRIA RAM ASGARD LOKI RGB 2X8GB 3200MHZ
            </h5>
            <p className="slidetext">
              por <strong>R$292,69</strong> á vista ou em{" "}
              <strong>4x de R$73,17</strong> sem juros.
            </p>
            <a
              className="btn btn-outline-light btn-floating mx-2 btnfooter"
              href="https://s.click.aliexpress.com/e/_DmRiGe7"
              target="_blank">
              Ver oferta
            </a>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="itemcarousel">
          <img
            className="d-block w-100 carouselimg"
            src={ssd}
            alt="Second slide"
          />

          <Carousel.Caption>
            <h5 className="slidetext titleslider">SSD GOLDENFIR 128GB</h5>
            <p className="slidetext">
              por <strong>R$61,69</strong> á vista ou em{" "}
              <strong>4x de 15,42</strong> sem juros.
            </p>
            <a
              className="btn btn-outline-light btn-floating mx-2 btnfooter"
              href="https://s.click.aliexpress.com/e/_DCvEgRl"
              target="_blank">
              Ver oferta
            </a>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="itemcarousel">
          <img
            className="d-block w-100 carouselimg"
            src={chair}
            alt="Third slide"
          />

          <Carousel.Caption>
            <h5 className="slidetext titleslider">
              CADEIRA GAMER MOOBX GT RACER
            </h5>
            <p className="slidetext">
              por <strong>R$ 824,73</strong> à vista ou{" "}
              <strong>R$ 824,73</strong> em <strong>6x de R$ 137,46</strong> sem
              juros.
            </p>
            <a
              className="btn btn-outline-light btn-floating mx-2 btnfooter"
              href="https://www.magazinevoce.com.br/magazineEVOofertas/cadeira-gamer-moobx-gt-racer-preto/p/ac8e1haage/MO/MECG/?utm_source=EVOofertas&utm_medium=cadeira-gamer-moobx-gt-racer-preto&utm_campaign=copy-paste&utm_content=copy-paste-share"
              target="_blank">
              Ver oferta
            </a>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="itemcarousel">
          <img
            className="d-block w-100 carouselimg"
            src={notebook}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 className="slidetext titleslider">
              NOTEBOOK GAMER CORE i5 - 144Hz - 8GB - RTX 2050 - 512GB SSD
            </h5>
            <p className="slidetext">
              por <strong>R$ 3.999,00</strong> à vista ou{" "}
              <strong>R$ 4.443,33</strong> em <strong>10x de R$ 444,33</strong>{" "}
              sem juros.
            </p>
            <h6>
              <strong>CUPOM: 50EVOOFERTAS</strong>
            </h6>
            <a
              className="btn btn-outline-light btn-floating mx-2 btnfooter"
              href="https://www.magazinevoce.com.br/magazineevoofertas/notebook-gamer-acer-nitro-intel-core-i5-8gb-512gb-156-144hz-ips-rtx-2050-windows-11-anv15-51-58ql/p/238306500/in/nota/e"
              target="_blank">
              Ver oferta
            </a>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className="itemcarousel">
          <img
            className="d-block w-100 carouselimg"
            src={monitor}
            alt="First slide"
          />
          <Carousel.Caption>
            <h5 className="slidetext titleslider">
              Monitor Gamer AOC 24G2S/BK 23,8 Full HD 165Hz 1ms
            </h5>
            <p className="slidetext">
              por <strong>R$ 863,10</strong> à vista ou{" "}
              <strong>R$ 959,00</strong> em <strong>6x de R$ 159,83</strong> sem
              juros.
            </p>
            <a
              className="btn btn-outline-light btn-floating mx-2 btnfooter"
              href="https://www.magazinevoce.com.br/magazineevoofertas/monitor-gamer-aoc-24g2s-bk-238-full-hd-165hz-1ms-hdmi-displayport/p/237272800/in/mogm/"
              target="_blank">
              Ver oferta
            </a>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
