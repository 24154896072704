import axios from "axios";
import { useEffect, useState } from "react";
import { Heards } from "../../secure/auth/AuthTokenGetData";
import { UrlSite } from "./UrlAccess";

export function UseMemoryramFetch(brandSelected, motheboardId) {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchDataMemoryram = async () => {
            try {
                if (brandSelected && motheboardId) {
                    const res = await axios.get(`${UrlSite}/node-api/memoryramcompatibles/${brandSelected}/${motheboardId}`, Heards);
                    setData(res.data);
                }
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchDataMemoryram();

    }, [brandSelected, motheboardId]);

    return { data, loading, error };
}
