/** @format */
import Alert from "@mui/material/Alert";

export function AlertTopHomeInfos() {
  return (
    <div className="divalerttop">
      <Alert
        className="alertprincipal"
        severity="info"
        sx={{ bgcolor: "#000000", color: "#696969" }}>
        <p>
          {" "}
          Não tem certeza de qual configuração escolher? Consulte nossa tabela
          de recomendações para encontrar a configuração ideal para você. Basta
          escolher o valor que você gostaria de investir e nós recomendaremos a
          configuração que melhor se adapta às suas necessidades.
          <br />
          <br />
          <a
            href="/recommendations"
            className="btn btn-outline-light bottomconfirm text-sm placeholder:text-zinc-500 text-orange">
            <strong>
              <i>Ver recomendações</i>
            </strong>
          </a>
        </p>
      </Alert>
      <br />
    </div>
  );
}
