/** @format */

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

import amdvsintel from "../../img/amdvsintel.jpg";
import amdvsintelbench from "../../img/amdvsintelbench.jpg";
import amdvsintelbasic from "../../img/amdvsintelbasic.jpg";
import Slider from "../slider/slider";

import DonationsAnimated from "../../utils/DonationsAnimated";

import ScriptAnalytcs from "../../utils/ScriptGoogleAnalytcs";

export default function Tips() {
  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustCenter marginTopTips">
        <Navbar />

        <div className="tips container-expand-lg justify-content-center align-items-center">
          <h1 className="h1tips">
            <strong>Bem-vindo a área de dicas</strong>
          </h1>

          <p>
            Aqui te passarei algumas dicas de como pensar em uma configuração de
            PC.
          </p>

          <h2>Qual marca escolher?</h2>

          <br />

          <div className="imgtips">
            <img
              src={amdvsintel}
              alt="AMD VS INTEL"
              width={570}
              height={200}></img>
          </div>

          <br />
          <br />
          <p>
            Primeiro passo que precisamos fazer antes de começar a escolher as
            peças do PC é saber se ele será AMD ou INTEL, mas aí você deve
            pensar "como assim? Se eu não sei fazer justamente isso", qual a
            "melhor" opção? E a verdade é que não existe "melhor" opção, porém a
            que mais vai se encaixar no seu orçamento, pois vejamos bem os
            pontos positivos de cada uma das marcas em minha opinião.
          </p>

          <h3>Longo prazo</h3>
          <p>
            Visando uma plataforma para upgrades futuros, por exemplo eu iria
            sem dúvidas de AMD, pois a compatibilidade que ela possui em seu
            socket é bem maior que a da INTEL, vejamos um exemplo: uma B450
            AORUS é compatível desde a linha Athlon até a linha da "nova
            geração" da AMD conhecido como os ryzen 5000, enquanto os INTEL
            geralmente possui compatibilidade apenas para 2 gerações, exemplo: 9
            e 10gen.
          </p>
          <br />

          <h3>Preço</h3>
          <h5>Linha Intermediaria Ryzen 5 vs Core i5</h5>
          <p>
            Aqui entramos numa área bem complexa de escolha, pois os preços
            variam bastantes, mas vamos lá, uma configuração com uma:{" "}
            <strong>B450M + Ryzen 5600x</strong> sai mais ou menos por{" "}
            <strong>R$1.800 </strong>o KIT fazendo o uso do "Monte seu PC" nas
            lojas Terabyte e Pichau, enquanto uma configuração INTEL:{" "}
            <strong>B6600M + Core i5 12400F</strong> sai por volta de{" "}
            <strong>R$2.438</strong>, mas como você pode notar a b660M é uma
            placa-mãe mais recente, logo "mais bem construída" por isso um pouco
            mais cara que uma B450M, esse é um ponto que não podemos deixar de
            levar em consideração é claro. Enquanto ela gira em torno de R$1.200
            a R$1.400, as B450 conseguimos achar a depender do modelo de R$550 a
            R$1.100. outro ponto que devemos levar em consideração é que o
            12400f entrega mais desempenho que o 5600x, apesar de pouca
            diferença, em games geralmente ele fica de 2% a 5% em media na
            frente do ryzen 5600x, logo, se pra você qualquer FPS faz diferença,
            talvez a INTEL entre esses 2 seja uma melhor opção usando uma RTX
            3060 por exemplo.
          </p>

          <br />
          <h6>
            Veja agora um comparativo do site:{" "}
            <a href="https://hitesti.com/pt/compare/cpu/amd-ryzen-5-5600x-vs-intel-core-i5-12400f">
              Hitesti
            </a>{" "}
            e tire suas proprias conclusões
          </h6>

          <div className="imgtips">
            <img src={amdvsintelbench} alt="benchmark" />
          </div>

          <h6>
            Veja também esse video do "Tiozão" fazendo um comparativo de
            desempenho dos dois processadores.
          </h6>
          <iframe
            className="yotubetips"
            src="https://www.youtube.com/embed/pCNAclJyKb8?start=207"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

          <br />
          <br />
          <h5>Linha de "entrada" Ryzen 3 vs Core I3</h5>
          <p>
            Bom, aqui iremos ver apenas 1 exemplo é claro, mas vale ressaltar
            que dependendo do cenário em que você se encontra pode ser que
            compense mais um lado que o outro visando custo x benefício. Então
            vamos comparar o ryzen 3 4100 com o i3 10100 ? Bom, se falando de
            preço, isso irá variar a depender da placa-mãe que você irá escolher
            é claro, mas nesse exemplo, iremos pegar um cenário em que remos
            economizar nosso dinheiro, porém existem outros cenários que
            poderíamos levar em consideração, por exemplo: economizar agora em
            processador, porém pegar uma placa-mãe mais parrudinha visando
            futuros upgrades. Dito isto, seguiremos comparando os seguintes kits
            em termos de preço: <strong>I3 10100F + H410M ou H510M </strong>sai
            em torno de <strong>R$979,80 a R$1.100,00</strong> já a linha{" "}
            <strong>Ryzen 3 4100 + a320 ou uma a520</strong> (no caso da a320
            precisa de atualização na BIOS.) sai em torno de{" "}
            <strong>R$1.020,00 a R$1.100,00</strong> ambos sem video integrado,
            ou seja, você irá precisar comprar uma GPU para ambos os casos assim
            como no comparativo da linha intermediaria.
          </p>

          <br />
          <h6>
            Veja agora um comparativo feito no site:{" "}
            <a href="https://technical.city/pt/cpu/Core-i3-10100F-vs-Ryzen-3-4100">
              Technical
            </a>
          </h6>

          <div className="imgtips">
            <img src={amdvsintelbasic} alt="AMD VS INTEL" />
          </div>

          <div id="atContainer-48eb8ab585d819466eb785748b3cb67f"></div>
          <h5>Linha com grafico integrado</h5>
          <p>
            E aqui iremos falar um pouco sobre os gráficos integrados, bom, se
            tratando de APU, vejo que a a AMD consegue se sobre sair se levarmos
            em consideração custo X benefínicio, a linha de APU da AMD é um
            pouco mais madura que as da INTEL, onde por exemplo a APU do ryzen
            5600G chega bem próximo de uma RX550 / 1050. dito isto você pode se
            basear por exemplo nesse video para ver alguns testes comparando as
            APU do Ryzen 5600G vs i5 12400 em games.
          </p>
          <br />

          <iframe
            className="yotubetips"
            src="https://www.youtube.com/embed/rgaYjXfidp8"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>

          <br />
          <br />
          <h4>Conclusão</h4>
          <p>
            Te mostrei alguns pontos a serem analisados, são muitas as variáveis
            que devemos levar em consideração para escolhermos as peças ideais,
            por tanto, apenas te dei alguns insights de como pensar e por onde
            começar a escolher as peças do seu PC., mas se o teu objetivo é
            edição de videos, por exemplo, eu vejo que os processadores Intel
            costumam entregar um pouco mais de desempenho (diferença pouco, mas
            que devemos levar sempre em consideração), já se o seu objetivo é
            gastar pouco, caso a configuração conte com GPU, em minha opinião
            você deve optar pelo kit que achar pelo menor preço. Caso seu
            objetivo seja pegar um kit com gráficos integrados (sem GPU) te
            indico a ir de AMD sem medo de ser feliz, e se for futuros upgrades,
            te indico AMD também.
          </p>
          <br />

          <Slider />

          <br />

          <DonationsAnimated />

          <Footer />
        </div>
      </div>
      <br />
    </>
  );
}
