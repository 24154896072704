/** @format */

import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import DonationsAnimated from "../../utils/DonationsAnimated";
import Slider from "../slider/slider";

import ScriptAnalytcs from "../../utils/ScriptGoogleAnalytcs";

export default function About() {
  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustCenter">
        <Navbar />

        <div className="aboutus container-expand-lg">
          <br />
          <h1>Sobre a "É compatível?"</h1>

          <h2>Qual o objetivo?</h2>
          <ul>
            <p>
              Tornar o início de quando estamos no processo da escolha das peças
              do PC mais fácil, tendo em um só lugar todas as informações que
              precisamos a cerca das peças que estamos escolhendo/pensando, mas
              não sabemos se a placa-mãe irá suportar/ser compatível
            </p>
          </ul>
          <div id="atContainer-48eb8ab585d819466eb785748b3cb67f"></div>
          <h2>Como surgiu?</h2>
          <ul>
            <p>
              No ano de 2020 quando eu (João Vitor), estava pesquisando sobre
              quais marcas e peças escolher visando o melhor custo x beneficio
              possível, criei então um grupo no whatsapp "PC Gamer barato" (que
              existe até hoje) e de cara lotou com mais de 200 pessoas nele.
              Logo percebi a dor que todos nós tínhamos e fomos um ajudando o
              outro e repassando dicas através da experiência de cada um mebro
              do grupo.
            </p>
          </ul>

          <h2>O que o site faz?</h2>
          <ul>
            <p>
              Permite com que escolhemos as peças que estamos pensando/desejando
              comprar e verificar a compatibilidade no geral, seguindo a ordem
              de escala: Marca {""} Placa-mãe {""} Processador e memoria ram.
              (Ao selecionar a placa-mãe ela já retornar ambos os itens que são
              compatíveis com a mesma, de acordo com suas fabricantes.)
            </p>
          </ul>

          <h2>Possui loja fisica?</h2>
          <ul>
            <p>
              Não, o site é feito apenas para ajudar de alguma forma as pessoas,
              sem qualquer vínculo inclusive com quais quer marcas.
            </p>
          </ul>

          <Slider />

          <DonationsAnimated />

          <Footer />
          <br />
        </div>
      </div>
    </>
  );
}
