/** @format */

import { Link } from "react-router-dom";
import logo from "../img/logoBlack.jpeg";
import discordLogo from "../img/discord.png";

export default function Navbar() {
  return (
    <nav className="navbar navbarEdit navbarmenu navbar-expand-lg flex fixed-top pb-4">
      <script
        async
        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4960559188017689"
        crossorigin="anonymous"></script>

      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css"
        rel="stylesheet"
        integrity="sha384-iYQeCzEYFbKjA/T2uDLTpkwGzCiq6soy8tYaI1GyVh/UjpbCx/TYkiZhlZB6+fzT"
        crossorigin="anonymous"></link>

      <div className="container-md flex align-items-center justify-content-center">
        <div className="">
          <a href="/">
            <Link to="/">
              <img
                id="logoimg"
                src={logo}
                alt="écompativel?"
                width={300}
                height={100}
              />
            </Link>
          </a>
        </div>
      </div>

      <div className="discordLogo">
        <a
          href="https://discord.gg/Me9cn5pyEQ"
          target="_blank"
          rel="noopener noreferrer">
          <img
            id="discord"
            src={discordLogo}
            alt="écompativel?-discord"
            width={60}
            height={50}
          />
        </a>
        <p>Discord</p>
      </div>
    </nav>
  );
}
