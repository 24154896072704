/** @format */

import { useState, React } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import DonationsAnimated from "../../utils/DonationsAnimated";
import MySlider from "../slider/slider";
import Scripts from "../../utils/Scripts";

import ScriptAnalytcs from "../../utils/ScriptGoogleAnalytcs";

export default function Recomendations() {
  const [brandSelected, setSelectedbrand] = useState("0");
  const [wantGPUSelected, setWantGPU] = useState("0");
  const [priceSelected, setPriceSelected] = useState("0");

  function handleSelectBrand(event) {
    const brand = event.target.value;
    setSelectedbrand(brand);
  }

  function WantVideoCard(event) {
    const wantGPUSelected = event.target.value;
    setWantGPU(wantGPUSelected);
  }

  function PriceSelected(event) {
    const priceselected = event.target.value;
    setPriceSelected(priceselected);
  }

  // EMPTY TABLE

  const [EmptyTable, setEmptyTable] = useState([
    ["Computador com desempenho de PS5", "", ""],
    ["Processador", "Ryzen 7 5700x", "https://amzn.to/4bNyL4b"],
    ["Placa mãe", "Biostar B550mh", "https://amzn.to/3KtW2wt"],
    ["Memoria ram", "Asgard 2x(8gb) ", "https://amzn.to/3QU5MUh"],
    ["SSD", "Kingston NV2 ", "https://amzn.to/3URGLdg"],
    [
      "GPU",
      "XFX AMD Radeon RX 6600 8GB",
      "https://www.magazinevoce.com.br/magazineevoofertas/placa-de-video-rx-6600-cld-8g-asrock-amd-radeon-8gb-gddr6-90-ga2rzz-00uanf/p/kb0d5bafec/in/pcvd/ ",
    ],
    [
      "Fonte",
      "MSI MAG A650BN 650w 80 Plus Bronze ",
      "https://www.magazinevoce.com.br/magazineevoofertas/fonte-msi-mag-a650bn-atx-650w-80-plus-bronze-pfc-ativo-entrada-bivolt-preto-306-7zp2b22-ce0/p/jf0eb90c94/in/ftpc/",
    ],
    ["Gabinete", "De sua preferência", "SEM"],
  ]);

  // AMD CHOOSE NOT GPU
  const [tableDataAmdTwoThousandAPU, setTableDataTwoThousandAmdAPU] = useState([
    ["", "", "Te recomendo comprar com"],
    ["Processador", "Ryzen 3 3200G", "https://amzn.to/3QUM5eV"],
    ["Placa mãe", "ASROCK A320M-HD", "SEM"],
    [
      "Memoria ram",
      "1x8GB TEAM GROUP T-FORCE VULCAN, 3000MHZ",
      "https://amzn.to/4as1Y3h",
    ],
    ["SSD", "Goldenfir 512GB", "https://amzn.to/3K6Y719"],
    ["Fonte", "Aerocool KCAS 400W, 80 Plus White", "https://amzn.to/3ysqMek"],
    ["Gabinete", "De sua preferencia até R$200,00", "SEM"],
  ]);

  const [tableDataAmdThreeThousandAPU, setTableDataThreeThousandAmdApu] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Ryzen 5 5600G", "https://amzn.to/4bJBQSO"],
      ["Placa mãe", "ASUS PRIME A520M-E", "https://amzn.to/3yvMKgI"],
      ["Memoria ram", "2x8GB ASGARD RGB, 3200MHZ", "https://amzn.to/3QVaNMm"],
      ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
      [
        "Fonte",
        "Gamemax GP650 650W GP, 80 Plus Bronze",
        "https://amzn.to/4bnml2V",
      ],
      [
        "Gabinete",
        "De sua preferencia até R$300,00",
        "https://bityli.com/043Xv",
      ],
    ]);

  const [tableDataAmdFourThousandAPU, setTableDataFourThousandAmdApu] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Ryzen 5 7600G", "https://amzn.to/45640W5"],
      ["Placa mãe", "Gigabyte B550M DS3H", "https://amzn.to/4arBEqb"],
      ["Memoria ram", "2x8GB ASGARD RGB, 3200MHZ", "https://amzn.to/3V6IcWC"],
      ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
      ["HD", "Seagate Barracuda 1TB", "https://amzn.to/4annUNa"],
      [
        "Fonte",
        "Gamemax GP650 650W GP, 80 Plus Bronze",
        "https://amzn.to/4bnml2V",
      ],
      ["Gabinete", "De sua preferencia até R$400,00", "https://shre.ink/cLnx"],
    ]);

  // AMD CHOOSE WITH GPU
  // CONTINUAR DAQUI...
  const [tableDataAmdThreeThousandGPU, setTableDataThreeThousandAmdGPU] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Ryzen 5 5600x", "https://amzn.to/4dLzYL4"],
      ["Placa mãe", "ASUS PRIME A520M-E", "https://amzn.to/3yq2NfL"],
      ["Memoria ram", "1x8GB TEAM GROUP T-FORCE VULCAN, 3200MHZ", "SEM"],
      ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
      ["GPU", "NVIDIA GTX 1650", "https://bityli.com/8Nt0L"],
      ["Fonte", "Gamemax 500W GM, 80 Plus Bronze", "SEM"],
      [
        "Gabinete",
        "De sua preferencia até R$200,00",
        "https://bityli.com/HiM9b",
      ],
    ]);

  const [tableDataAmdFourThousandGPU, setTableDataFourThousandAmdGPU] =
    useState([
      ["", "", "Te recomendo comprar com"],
      [
        "Processador",
        "Ryzen 5 5600",
        "https://s.click.aliexpress.com/e/_DF6uyCz",
      ],
      ["Placa mãe", "ASUS Prime B550M-K", "SEM"],
      ["Memoria ram", "2x8GB Geil Evo Potenza, 3000MHZ", "SEM"],
      ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
      ["HD", "Seagate Barracuda 1TB", "https://amzn.to/4annUNa"],
      ["GPU", "NVIDIA GTX 1660 super", "https://amzn.to/3wJheem"],
      ["Fonte", "Gamemax 750W GP, 80 Plus Bronze", "https://amzn.to/4ask7OD"],
      [
        "Gabinete",
        "De sua preferencia até R$250,00",
        "https://bityli.com/kB9rT",
      ],
    ]);

  const [tableDataAmdSixThousandGPU, setTableDataSixThousandAmdGPU] = useState([
    ["", "", "Te recomendo comprar com"],
    [
      "Processador",
      "Ryzen 7 5700x",
      "https://s.click.aliexpress.com/e/_DBE1qU5",
    ],
    ["Placa mãe", "ASUS Prime B550M-K", "SEM"],
    ["Memoria ram", "2x8GB Geil Evo Potenza, 3000MHZ", "SEM"],
    ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
    ["SSD", "Seagate Barracuda 1TB", "https://amzn.to/4annUNa"],
    ["GPU", "NVIDIA RTX 3060", "https://amzn.to/3wFf0wJ"],
    ["Fonte", "Gamemax 750W GP, 80 Plus Bronze", "https://amzn.to/4ask7OD"],
    ["Gabinete", "De sua preferencia até R$250,00", "https://bityli.com/kB9rT"],
  ]);

  // INTEL CHOOSE NOT GPU
  const [tableDataIntelTwoThousandAPU, setTableDataTwoThousandIntelApu] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Core i3 10100", "https://amzn.to/4dNcHZ0"],
      ["Placa mãe", "Colorful H510M-K", "https://amzn.to/4boNH97"],
      [
        "Memoria ram",
        "1x8GB Kingston hyperx fúria, 2666MHZ",
        "https://amzn.to/3V9KKUe",
      ],
      ["SSD", "Goldenfir 512GB", "https://amzn.to/3WWbNDs"],
      ["Fonte", "Aerocool KCAS 400W, 80 Plus White", "https://amzn.to/3yAW6aF"],
      [
        "Gabinete",
        "De sua preferencia até R$200,00",
        "https://bityli.com/HiM9b",
      ],
    ]);

  const [tableDataIntelThreeThousandAPU, setTableDataThreeThousandIntelApu] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Core i5 11400F", "https://amzn.to/3WLS6hF"],
      ["Placa mãe", "Biostar B560MH-E 2.0", "https://amzn.to/3WP7t9b"],
      [
        "Memoria ram",
        "1x8GB Kingston hyperx fúria, 2666MHZ",
        "https://amzn.to/3V9KKUe",
      ],
      ["SSD", "Goldenfir 512GB", "https://amzn.to/3WWbNDs"],
      ["Fonte", "Aerocool KCAS 500W, 80 Plus White", "https://amzn.to/3yAW6aF"],
      [
        "Gabinete",
        "De sua preferencia até R$300,00",
        "https://bityli.com/043Xv",
      ],
    ]);

  const [tableDataIntelFourThousandAPU, setTableDataFourThousandIntelApu] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Core i7 11400", "SEM"],
      ["Placa mãe", "Gigabyte B560M GAMING HD", "SEM"],
      ["Memoria ram", "2x8GB ASGARD, 3200mhz", "https://amzn.to/3yua8Lv"],
      ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
      ["HD", "Seagate Barracuda 1TB", "https://amzn.to/4annUNa"],
      ["Fonte", "Gamemax GM600W, 80 Plus White", "https://amzn.to/4avJKhA"],
      [
        "Gabinete",
        "De sua preferencia até R$300,00",
        "https://bityli.com/043Xv",
      ],
    ]);

  // INTEL CHOOSE WITH GPU

  const [tableDataIntelThreeThousandGPU, setTableDataThreeThousandIntelGPU] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Core i3 10100F", "https://amzn.to/4av9vyk"],
      ["Placa mãe", "Biostar H410MH", "https://amzn.to/3ypkRXj"],
      [
        "Memoria ram",
        "1x8GB Kingston hyperx fúria, 2666MHZ",
        "https://amzn.to/3V9KKUe",
      ],
      ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
      ["GPU", "NVIDIA GTX 1650", "https://bityli.com/8Nt0L"],
      ["Fonte", "Aerocool KCAS 500W, 80 Plus White", "https://amzn.to/3UPyZkf"],
      [
        "Gabinete",
        "De sua preferencia até R$200,00",
        "https://bityli.com/HiM9b",
      ],
    ]);

  const [tableDataIntelFourThousandGPU, setTableDataFourThousandIntelGPU] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Core i5 11400F", "SEM"],
      ["Placa mãe", "Biostar B560MH-E 2.0", "https://amzn.to/3WP7t9b"],
      [
        "Memoria ram",
        "2x8GB Kingston hyperx fúria, 2666MHZ",
        "https://amzn.to/3V9KKUe",
      ],
      ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
      ["HD", "Seagate Barracuda 1TB", "https://amzn.to/4annUNa"],
      ["GPU", "NVIDIA GTX 1660 super", "https://amzn.to/3wJheem"],
      ["Fonte", "Gamemax 650W GP, 80 PLUS BRONZE", "https://amzn.to/4btCnsm"],
      [
        "Gabinete",
        "De sua preferencia até R$300,00",
        "https://bityli.com/043Xv",
      ],
    ]);

  const [tableDataIntelSixThousandGPU, setTableDataSixThousandIntelGPU] =
    useState([
      ["", "", "Te recomendo comprar com"],
      ["Processador", "Core i7 11400", "SEM"],
      ["Placa mãe", "Duex DX-H610ZG M2", "SEM"],
      ["Memoria ram", "2x8GB XPG D60", "SEM"],
      ["SSD", " Kingston NV2 500GB NVMe", "https://amzn.to/4dLzYL4"],
      ["HD", "Seagate Barracuda 1TB", "https://amzn.to/4annUNa"],
      ["GPU", "SAPPHIRE PULSE AMD RADEON RX 7600", "https://amzn.to/3V9uORV"],
      ["Fonte", "Gamemax 650w GP, 80 PLUS BRONZE", "https://amzn.to/4btCnsm"],
      [
        "Gabinete",
        "De sua preferencia até R$300,00",
        "https://bityli.com/043Xv",
      ],
    ]);

  const [copiedLinks, setCopiedLinks] = useState(
    Array(tableDataIntelSixThousandGPU.length).fill(false)
  );

  return (
    <>
      <ScriptAnalytcs />
      <div className="ajustRecomendationsToSmartphone ajustCenter marginTopRecomendations text-white">
        <Navbar />
        <Scripts />
        <link
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.1/dist/css/bootstrap.min.css"
          rel="stylesheet"
          integrity="sha384-iYQeCzEYFbKjA/T2uDLTpkwGzCiq6soy8tYaI1GyVh/UjpbCx/TYkiZhlZB6+fzT"
          crossOrigin="anonymous"></link>
        <div className="container-expand-lg containervar divform text-white">
          <form className="formpricipal py text-sm placeholder:text-zinc-500 appearance-none bg-black ">
            <div>
              <h2 className="form-floating h1form">Configuração recomendada</h2>
              <label className="form-floating label textrecomendations">
                Escolha uma marca
              </label>
              <select
                className="form-select flex"
                name="brand"
                id="brand"
                onChange={handleSelectBrand}>
                <option value="">Selecione a opção</option>
                <option value="AMD">AMD</option>
                <option value="INTEL">INTEL</option>
              </select>

              <label className="form-floating label textrecomendations">
                Com placa de vídeo?
              </label>
              <select
                className="form-select flex"
                name="wantgpu"
                id="wantgpu"
                onChange={WantVideoCard}>
                <option value="">Selecione a opção</option>
                <option value="TRUE">Sim</option>
                <option value="FALSE">Não</option>
              </select>

              <label className="form-floating label textrecomendations">
                Selecione o valor
              </label>
              {wantGPUSelected === "TRUE" ? (
                <select
                  className="form-select flex"
                  name="priceselected"
                  id="priceselected"
                  onChange={PriceSelected}>
                  <option value="">Selecione o valor</option>
                  <option value="threethousand">até R$3.000,00</option>
                  <option value="fourthounsand">até R$4.500,00</option>
                  <option value="sixthousand">Até R$6.000,00</option>
                </select>
              ) : (
                <select
                  className="form-select flex"
                  name="priceselected"
                  id="priceselected"
                  onChange={PriceSelected}>
                  <option value="">Selecione o valor</option>
                  <option value="twothousand">até R$2.000,00</option>
                  <option value="threethousand">até R$3.000,00</option>
                  <option value="fourthounsand">até R$4.500,00</option>
                </select>
              )}
            </div>

            <div>
              <p id="tips" className="text-sm placeholder:text-zinc-500"></p>
            </div>
          </form>

          <div className="table-responsive tablecenter" align="center">
            {brandSelected === "AMD" &&
            wantGPUSelected === "FALSE" &&
            priceSelected === "twothousand" ? (
              <table className="">
                {tableDataAmdTwoThousandAPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataAmdTwoThousandAPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataTwoThousandAmdAPU(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "AMD" &&
              wantGPUSelected === "FALSE" &&
              priceSelected === "threethousand" ? (
              <table>
                {tableDataAmdThreeThousandAPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          style={{ color: "red" }}
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataAmdThreeThousandAPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataThreeThousandAmdApu(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "AMD" &&
              wantGPUSelected === "FALSE" &&
              priceSelected === "fourthounsand" ? (
              <table>
                {tableDataAmdFourThousandAPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataAmdFourThousandAPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataFourThousandAmdApu(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "INTEL" &&
              wantGPUSelected === "FALSE" &&
              priceSelected === "twothousand" ? (
              <table>
                {tableDataIntelTwoThousandAPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataIntelTwoThousandAPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataTwoThousandIntelApu(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "INTEL" &&
              wantGPUSelected === "FALSE" &&
              priceSelected === "threethousand" ? (
              <table>
                {tableDataIntelThreeThousandAPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataIntelThreeThousandAPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataThreeThousandIntelApu(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "INTEL" &&
              wantGPUSelected === "FALSE" &&
              priceSelected === "fourthounsand" ? (
              <table>
                {tableDataIntelFourThousandAPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataIntelFourThousandAPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataFourThousandIntelApu(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "AMD" &&
              wantGPUSelected === "TRUE" &&
              priceSelected === "threethousand" ? (
              <table>
                {tableDataAmdThreeThousandGPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataAmdThreeThousandGPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataThreeThousandAmdGPU(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "AMD" &&
              wantGPUSelected === "TRUE" &&
              priceSelected === "fourthounsand" ? (
              <table>
                {tableDataAmdFourThousandGPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataAmdFourThousandGPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataFourThousandAmdGPU(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "AMD" &&
              wantGPUSelected === "TRUE" &&
              priceSelected === "sixthousand" ? (
              <table>
                {tableDataAmdSixThousandGPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataAmdSixThousandGPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataSixThousandAmdGPU(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "INTEL" &&
              wantGPUSelected === "TRUE" &&
              priceSelected === "threethousand" ? (
              <table>
                {tableDataIntelThreeThousandGPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataIntelThreeThousandGPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataThreeThousandIntelGPU(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "INTEL" &&
              wantGPUSelected === "TRUE" &&
              priceSelected === "fourthounsand" ? (
              <table>
                {tableDataIntelFourThousandGPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataIntelFourThousandGPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataFourThousandIntelGPU(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : brandSelected === "INTEL" &&
              wantGPUSelected === "TRUE" &&
              priceSelected === "sixthousand" ? (
              <table>
                {tableDataIntelSixThousandGPU.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [
                              ...tableDataIntelSixThousandGPU,
                            ];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setTableDataSixThousandIntelGPU(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            ) : (
              <table>
                {EmptyTable.map((row, rowIndex) => (
                  <tr key={rowIndex}>
                    {row.map((cellData, cellIndex) => (
                      <td key={`${rowIndex}-${cellIndex}`}>
                        <input
                          disabled
                          type="text"
                          value={cellData}
                          onChange={(e) => {
                            const newTableData = [...EmptyTable];
                            newTableData[rowIndex][cellIndex] = e.target.value;
                            setEmptyTable(newTableData);
                          }}
                        />
                      </td>
                    ))}
                    <td>
                      <div className="">
                        {row[2] !== "" &&
                          row[2] !== "SEM" &&
                          row[2] !== "Te recomendo comprar com" && (
                            <button
                              className="btn btn-outline-dark btn-white btncopy"
                              onClick={() => {
                                const link = row[2];
                                navigator.clipboard.writeText(link);
                                setCopiedLinks((prevCopiedLinks) => {
                                  const newCopiedLinks = [...prevCopiedLinks];
                                  newCopiedLinks[rowIndex] = true;
                                  return newCopiedLinks;
                                });
                              }}>
                              {copiedLinks[rowIndex]
                                ? "Copiado"
                                : "Copiar link"}
                            </button>
                          )}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            )}
            <br />
            <MySlider />
            <DonationsAnimated />
            <br />
            <Footer />
            <br /> <br />
          </div>
        </div>
      </div>
    </>
  );
}
